/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from 'react';

export const InterfaceSettingsFuture = ({ className }) => {
  return (
    <svg
      className={`interface-settings-future ${className}`}
      fill='none'
      height='32'
      viewBox='0 0 32 32'
      width='32'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        className='path'
        d='M18.1341 28.1013L25.4145 23.898C26.1916 23.4493 26.5794 23.2249 26.8621 22.9111C27.1121 22.6333 27.3012 22.3062 27.4167 21.9508C27.5469 21.5501 27.5469 21.1022 27.5469 20.209V11.7902C27.5469 10.897 27.5469 10.4492 27.4167 10.0484C27.3012 9.69301 27.1121 9.36563 26.8621 9.08791C26.5807 8.7754 26.1937 8.55199 25.4232 8.10715L18.1328 3.89804C17.3556 3.44933 16.9678 3.22543 16.5547 3.13762C16.1892 3.05992 15.8112 3.05992 15.4457 3.13762C15.0326 3.22543 14.6435 3.44933 13.8663 3.89804L6.58458 8.10213C5.8083 8.55031 5.42047 8.77422 5.13802 9.08791C4.88796 9.36563 4.69912 9.69301 4.58364 10.0484C4.45312 10.4501 4.45312 10.8991 4.45312 11.7965V20.2031C4.45312 21.1005 4.45312 21.5491 4.58364 21.9508C4.69912 22.3062 4.88796 22.6333 5.13802 22.9111C5.42063 23.2249 5.80876 23.4493 6.58594 23.898L13.8663 28.1013C14.6434 28.55 15.0326 28.774 15.4457 28.8619C15.8112 28.9396 16.1892 28.9396 16.5547 28.8619C16.9678 28.774 17.3569 28.55 18.1341 28.1013Z'
        stroke='#263D49'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='3'
      />
      <path
        className='path'
        d='M12 15.9996C12 18.2087 13.7909 19.9996 16 19.9996C18.2091 19.9996 20 18.2087 20 15.9996C20 13.7905 18.2091 11.9996 16 11.9996C13.7909 11.9996 12 13.7905 12 15.9996Z'
        stroke='#263D49'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='3'
      />
    </svg>
  );
};
