import React, { createContext, useEffect, useState } from 'react';

const ModalContext = createContext(null);
export { ModalContext };
export default function ModalProvider({ children }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [contextObj, setContextObj] = useState({
    open,
    handleOpen,
    handleClose,
  });
  useEffect(() => {
    setContextObj((prev) => ({ ...prev, open }));
  }, [open]);

  return (
    <ModalContext.Provider value={contextObj}>{children}</ModalContext.Provider>
  );
}
