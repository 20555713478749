/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const InterfaceChartLine = ({ className }) => {
  return (
    <svg
      className={`interface-chart-line ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M2.5 12.5005V14.0003C2.5 14.9337 2.5 15.4001 2.68166 15.7567C2.84144 16.0703 3.09623 16.3257 3.40983 16.4855C3.766 16.667 4.23249 16.667 5.16409 16.667H17.5002M2.5 12.5005V4.16699M2.5 12.5005L5.71116 9.82454L5.71382 9.82241C6.29472 9.33833 6.58573 9.09582 6.90129 8.99731C7.27406 8.88095 7.67559 8.89935 8.0363 9.04899C8.34208 9.17584 8.61021 9.44397 9.14647 9.98023L9.15185 9.98561C9.69643 10.5302 9.96944 10.8032 10.2801 10.9298C10.6478 11.0796 11.0571 11.0925 11.4339 10.9675C11.7534 10.8615 12.0452 10.6066 12.6286 10.0961L17.5 5.83366"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.66667"
      />
    </svg>
  );
};
