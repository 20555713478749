import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  EARLY_ACCESS_OPT,
  LOCATION_TYPE,
  setWindowLocation,
} from '../../utils/constants';
import { ArrowArrowRightMd } from '../../assets/icons/ArrowArrowRightMd';
import MENUALTIMG from '../../icons/Menu_Alt_01.png';
import './style.css';
import { MenuAlt1 } from '../../icons/MenuAlt1';
import { MenuCloseMd } from '../../icons/MenuCloseMd';
import { ModalContext } from '../../providers/ModalProvider';

export const HeaderNew = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const modalProvider = useContext(ModalContext);
  const { handleOpen } = modalProvider;
  const handleOnClickOpt = { onClick: () => handleOpen() };
  // useEffect(() => {
  //   console.log('open', open);
  // }, [open]);
  return (
    <>
      <div className='group header_new width-controller-wrapper'>
        <div className='first-half'>
          <img
            className='img'
            alt='Group'
            src='https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/6513c9bb47fa9724c4d455f4/img/group-39773-2.png'
            onClick={() => navigate('/')}
          />
        </div>
        <div className='second-half'>
          <div className='frame'>
            <div
              className='text-wrapper-2'
              onClick={() => {
                setWindowLocation(LOCATION_TYPE.product.goto);
              }}
            >
              Product
            </div>
            <div
              className='text-wrapper-2'
              onClick={() => {
                setWindowLocation(LOCATION_TYPE.usecase.goto);
              }}
            >
              Use Cases
            </div>
            <div className='text-wrapper-2' onClick={() => navigate('/about')}>
              About Us
            </div>
          </div>
          <div className='overlap-group-wrapper'>
            <div className='overlap-group'>
              <div className='rectangle' />
              <div className='div'>
                <div className='text-wrapper' {...handleOnClickOpt}>
                  Get Early Access
                </div>
                <ArrowArrowRightMd className='arrow-arrow-right-MD' />
              </div>
            </div>
          </div>
        </div>
        <div className='mob-second-half'>
          {!menuOpen && (
            <div className='img-wrapper' onClick={() => setMenuOpen(true)}>
              <MenuAlt1 />
            </div>
          )}
        </div>
      </div>
      <div className={`dropdown-menu-wrapper ${menuOpen ? 'open' : ''}`}>
        <div className={`menu-wrapper`}>
          <div className='close-button' onClick={() => setMenuOpen(false)}>
            <MenuCloseMd className='menu-close-MD' />
          </div>
          <div className='frame-2'>
            <div
              className='text-wrapper-9'
              onClick={() => {
                setMenuOpen(false);
                setWindowLocation(LOCATION_TYPE.product.goto);
              }}
            >
              Product
            </div>
            <div
              className='text-wrapper-9'
              onClick={() => {
                setMenuOpen(false);
                setWindowLocation(LOCATION_TYPE.usecase.goto);
              }}
            >
              Use Cases
            </div>
            <div
              className='text-wrapper-9'
              onClick={() => {
                setMenuOpen(false);
                navigate('/about');
              }}
            >
              About Us
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
