/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const WarningShieldCheck1 = ({ className }) => {
  return (
    <svg
      className={`warning-shield-check-1 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M12.5026 7.5L9.16927 10.8333L7.5026 9.16667M16.6693 8.47087C16.6693 13.9444 12.5292 16.3984 10.7741 17.1928L10.7719 17.1938C10.5873 17.2773 10.4948 17.3192 10.285 17.3552C10.1522 17.378 9.85389 17.378 9.72105 17.3552C9.51045 17.3191 9.41696 17.2771 9.2309 17.1928C7.47577 16.3984 3.33594 13.9444 3.33594 8.47087V5.16683C3.33594 4.23341 3.33594 3.76635 3.51759 3.40983C3.67738 3.09623 3.93216 2.84144 4.24577 2.68166C4.60229 2.5 5.06935 2.5 6.00277 2.5H14.0028C14.9362 2.5 15.4022 2.5 15.7588 2.68166C16.0724 2.84144 16.328 3.09623 16.4878 3.40983C16.6693 3.766 16.6693 4.2325 16.6693 5.16409V8.47087Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.66667"
      />
    </svg>
  );
};
