import { Box } from '@mui/material';
import React from 'react';
import './index.css';
import Blog1 from './blog1';

export default function BlogFull() {
  return (
    <Box className='page-wrapper'>
      <Blog1 />
    </Box>
  );
}
