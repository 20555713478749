import React, { Component } from 'react';
// import './index.css';
import { Link } from 'react-router-dom';
import { Box, Grid } from '@mui/material';

export default function CompanyDetails({ dataObj }) {
  return (
    <Box className='company-details'>
      <Box className='title-wrapper'>
        <Box className='title'>{dataObj?.title || 'Job Title'}</Box>
      </Box>
      <Box className='section-1'>
        <p>
          Anvai is an AI software company changing how enterprises unlock value
          from the vast amounts of unstructured text data they grapple with
          daily, e.g., financial and ESG reports, news, social media, email,
          slack, legal contracts, invoices, customer interaction data and
          employee feedback. Our AI-based intelligent text processing platform
          can:
        </p>
        <ul>
          <li>Analyze large amounts of data from multiple sources</li>
          <li>Extract key business facts, entities and terms </li>
          <li>Discern sentiments and key factors driving them </li>
          <li>
            Categorize, summarize and make content in documents easily
            searchable
          </li>
        </ul>
        <p>
          Anvai is founded by seasoned AI technologists with over 60 years of
          combined experience in developing and deploying large scale AI
          applications in Fortune 50 and top internet companies across the
          globe. 2023 is a year of growth for us at Anvai and we plan to grow
          our team to support the rapid expansion of our product offering and
          client pipeline.
        </p>
        {dataObj?.details?.map((item, idx) => {
          return <p key={idx}>{item}</p>;
        })}
      </Box>
    </Box>
  );
}
