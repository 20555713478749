import { Box } from '@mui/material';
import React from 'react';
import './index.css';
import Section1 from './section1';
import Section2 from './section2';

export default function Blog() {
  return (
    <Box className='page-wrapper'>
      <Box>
        <Section1 />
      </Box>
    </Box>
  );
}
