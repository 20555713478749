import React, { Component } from 'react';
import './section1.css';
import { Link } from 'react-router-dom';
import ImgSrc from './img2.png';
import { Box, Grid } from '@mui/material';
import useRequestDemo from '../../../hooks/useRequestDemo';

export default function Section1() {
  // const { handleClick } = useRequestDemo();
  const handleClick = (e) => {
    document?.getElementById('open-position-box')?.scrollIntoView?.();
    console.log('e', e);
  };
  return (
    <Box className='section-grid-wrapper'>
      <Grid container spacing={2}>
        <Box component={Grid} item md={6} xs={12} display={{ md: 'none' }}>
          <div className='v19_108'>
            <img src={ImgSrc} style={{ width: '100%', borderRadius: 10 }} />
          </div>
        </Box>
        <Grid item md={3.5} xs={12}>
          <div className='v19_102'>
            <div className='v19_103'>
              <span className='v19_104_1'>Careers At Anvai</span>
              <span className='v19_104'>Work With us</span>
              <span className='v19_105'>
                We’re always eager to meet fresh talent, so check out our open
                positions.
              </span>
            </div>
            <div className='v19_106' onClick={handleClick}>
              <span className='v19_107'>Open Positions </span>
            </div>
          </div>
        </Grid>
        <Grid item md={2.5}></Grid>
        <Box
          component={Grid}
          item
          md={6}
          xs={12}
          display={{ xs: 'none', lg: 'block' }}
        >
          <div className='v19_108'>
            <img src={ImgSrc} style={{ width: '100%', borderRadius: 10 }} />
          </div>
        </Box>
      </Grid>
    </Box>
  );
}
