/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const SystemData = ({ className }) => {
  return (
    <svg
      className={`system-data ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M24 15.9997V22.6663C24 24.8755 20.4183 26.6663 16 26.6663C11.5817 26.6663 8 24.8755 8 22.6663V15.9997M24 15.9997V9.33301M24 15.9997C24 18.2088 20.4183 19.9997 16 19.9997C11.5817 19.9997 8 18.2088 8 15.9997M24 9.33301C24 7.12387 20.4183 5.33301 16 5.33301C11.5817 5.33301 8 7.12387 8 9.33301M24 9.33301C24 11.5421 20.4183 13.333 16 13.333C11.5817 13.333 8 11.5421 8 9.33301M8 15.9997V9.33301"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
