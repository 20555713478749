import React, { useContext } from 'react';
import './style.css';

import { ArrowArrowRightMd } from '../../../icons/ArrowArrowRightMd';
import { ArrowChevronRightDuo } from '../../../icons/ArrowChevronRightDuo';
import { CommunicationChatConversation } from '../../../icons/CommunicationChatConversation';
import { FileFileSearch } from '../../../icons/FileFileSearch';
import { InterfaceBookOpen } from '../../../icons/InterfaceBookOpen';
import { InterfaceChartBarHorizontal01 } from '../../../icons/InterfaceChartBarHorizontal01';
import { InterfaceChartLine } from '../../../icons/InterfaceChartLine';
import { MenuMoreGridBig1 } from '../../../icons/MenuMoreGridBig1';
import { SystemData } from '../../../icons/SystemData';
import { UserUserCardId } from '../../../icons/UserUserCardId';
import { WarningShieldCheck1 } from '../../../icons/WarningShieldCheck1';
import { Box, Grid } from '@mui/material';
import { EARLY_ACCESS_OPT } from '../../../utils/constants';
import { ModalContext } from '../../../providers/ModalProvider';

export const Frame1 = () => {
  const modalProvider = useContext(ModalContext);
  const { handleOpen } = modalProvider;
  const handleOnClickOpt = { onClick: () => handleOpen() };
  return (
    <Grid container>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <div className='overlap frame-1-custom'>
          <Grid container style={{ alignItems: 'center' }}>
            <Box
              component={Grid}
              item
              md={3}
              display={{ xs: 'none', md: 'block' }}
            >
              <div style={{ position: 'relative' }}>
                <div
                  style={{
                    position: 'absolute',
                    top: '-240px',
                    right: '150px',
                  }}
                >
                  <img
                    className='vector-2'
                    alt='Vector'
                    src='https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/65211d62e295b2bd3f0268f3/img/vector-33.svg'
                  />
                  <img
                    className='vector-3'
                    alt='Vector'
                    src='https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/65211d62e295b2bd3f0268f3/img/vector-34.svg'
                  />
                  <img
                    className='vector-4'
                    alt='Vector'
                    src='https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/65211d62e295b2bd3f0268f3/img/vector-35.svg'
                  />
                  <img
                    className='vector-5'
                    alt='Vector'
                    src='https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/65211d62e295b2bd3f0268f3/img/vector-36.svg'
                  />
                  <img
                    className='vector-6'
                    alt='Vector'
                    src='https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/65211d62e295b2bd3f0268f3/img/vector-37.svg'
                  />
                </div>
              </div>
              <div style={{ position: 'relative' }}>
                <div className='group-3'>
                  <div className='overlap-group-4'>
                    <div className='rectangle-6' />
                    <InterfaceBookOpen className='icon-instance-node-3' />
                  </div>
                </div>
                <div className='group-4'>
                  <div className='overlap-6'>
                    <div className='rectangle-7' />
                    <InterfaceChartBarHorizontal01 className='custom-icon-instance-node-2' />
                  </div>
                </div>
                <div className='group-13'>
                  <div className='overlap-group-4'>
                    <div className='rectangle-14' />
                    <FileFileSearch className='icon-instance-node-3' />
                  </div>
                </div>
                <div className='group-12'>
                  <div className='overlap-6'>
                    <div className='rectangle-13' />
                    <UserUserCardId className='custom-icon-instance-node-2' />
                  </div>
                </div>
                <div className='group-11'>
                  <div className='overlap-group-4'>
                    <div className='rectangle-12' />
                    <CommunicationChatConversation className='icon-instance-node-3' />
                  </div>
                </div>
                {/* 
              
              
              */}
              </div>
            </Box>
            <Grid item md={6}>
              <div className='group-wrapper'>
                <div className='group-7'>
                  <div className='group-8'>
                    <div className='frame-4'>
                      <div className='text-wrapper-8'>Unstructured Data</div>
                      <ArrowChevronRightDuo className='arrow-chevron-right' />
                      <div className='text-wrapper-8'>
                        Business Intelligence
                      </div>
                    </div>
                    <p className='text-wrapper-7'>
                      Build Custom AI Applications Faster
                    </p>
                    <p className='p'>
                      Tap unstructured data to maximize business performance
                    </p>
                    <div className='overlap-group-wrapper'>
                      <div className='overlap-group'>
                        <div className='rectangle' />
                        <div className='div'>
                          <div className='text-wrapper' {...handleOnClickOpt}>
                            Get Early Access
                          </div>
                          <ArrowArrowRightMd className='arrow-arrow-right-MD' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Box
              component={Grid}
              item
              md={3}
              display={{ xs: 'none', md: 'block' }}
              style={{ height: '60vh' }}
            >
              <div className='right-side-custom'>
                <img
                  className='group-10'
                  alt='Group'
                  src='https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/65211d62e295b2bd3f0268f3/img/group-39859@2x.png'
                />
                <div className='overlap-7'>
                  <div className='group-5'>
                    <div className='overlap-8'>
                      <div className='credit-risk'>CREDIT RISK MANAGEMENT</div>
                      <div className='custom-frame-2'>
                        <div className='custom-text-wrapper-5'>2020</div>
                        <div className='custom-rectangle-wrapper'>
                          <img
                            className='custom-rectangle-8'
                            alt='Rectangle'
                            src='https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/651536837a596e4ed4927eb7/img/rectangle-768.svg'
                          />
                        </div>
                      </div>
                      <div className='custom-frame-2'>
                        <div className='custom-text-wrapper-5'>2021</div>
                        <div className='custom-rectangle-wrapper'>
                          <img
                            className='custom-rectangle-9'
                            alt='Rectangle'
                            src='https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/651536837a596e4ed4927eb7/img/rectangle-769.svg'
                          />
                        </div>
                      </div>

                      <div className='custom-frame-2'>
                        <div className='custom-text-wrapper-5'>2022</div>
                        <div className='custom-rectangle-wrapper'>
                          <img
                            className='custom-rectangle-10'
                            alt='Rectangle'
                            src='https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/651536837a596e4ed4927eb7/img/rectangle-770.svg'
                          />
                        </div>
                      </div>

                      <div className='custom-frame-2'>
                        <div className='custom-text-wrapper-5'>2023</div>
                        <div className='custom-rectangle-wrapper'>
                          <img
                            className='custom-rectangle-11'
                            alt='Rectangle'
                            src='https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/651536837a596e4ed4927eb7/img/rectangle-771.svg'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  // className='overlap-2'
                  className='second-graph-wrapper'
                >
                  <div className='overlap-5'>
                    <div className='icon-instance-node-2'>
                      <div className='overlap-group-3'>
                        <div className='rectangle-5' />
                        <InterfaceChartLine className='icon-instance-node' />
                      </div>
                    </div>
                    <div className='text-wrapper-4'>Market Intelligence</div>
                  </div>
                  <div className='overlap-4'>
                    <div className='rectangle-2' />
                    <img
                      className='vector'
                      alt='Vector'
                      src='https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/65211d62e295b2bd3f0268f3/img/vector-41.svg'
                    />
                    <img
                      className='img-custom-class'
                      alt='Vector'
                      src='https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/65211d62e295b2bd3f0268f3/img/vector-43.svg'
                    />
                    <div className='ellipse' />
                    <div className='ellipse-2' />
                    <div className='ellipse-3' />
                    <div className='ellipse-4' />
                    <div className='div-wrapper'>
                      <div className='overlap-group-2'>
                        <div className='rectangle-3' />
                        <WarningShieldCheck1 className='icon-instance-node' />
                      </div>
                    </div>
                    <div className='text-wrapper-3'>Fraud Detection</div>
                  </div>
                </div>

                <div className='group-6'>
                  <div className='overlap-11'>
                    <div className='ESG-score-PEER'>
                      ESG SCORE PEER COMPARISON
                    </div>
                    <img
                      className='frame-3'
                      alt='Frame'
                      src='https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/651536837a596e4ed4927eb7/img/frame-39860.svg'
                    />
                  </div>
                </div>
              </div>
            </Box>
          </Grid>
          {/*
           */}
          <Grid
            container
            spacing={1}
            style={{ marginLeft: 0, marginTop: 20, width: '100%' }}
          >
            <Grid
              item
              xs={12}
              md={4}
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingRight: '8px',
              }}
            >
              <div className='group-18'>
                <div className='group-16'>
                  <div className='overlap-group-4'>
                    <div className='rectangle-16' />
                    <SystemData className='icon-instance-node-4' />
                  </div>
                </div>
                <div className='group-19'>
                  <div className='text-wrapper-11'>Domain-Specific Models</div>
                  <p className='text-wrapper-12'>
                    Custom built models for specific use cases
                  </p>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingRight: '8px',
              }}
            >
              <div className='group-18'>
                <div className='group-16'>
                  <div className='overlap-group-4'>
                    <div className='rectangle-12' />
                    <MenuMoreGridBig1 className='icon-instance-node-4' />
                  </div>
                </div>
                <div className='group-19'>
                  <div className='text-wrapper-11'>
                    Semantic Knowledge Discovery
                  </div>
                  <p className='text-wrapper-12'>
                    Actionable insights from semantically enriched knowledge
                    base
                  </p>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingRight: '8px',
              }}
            >
              <div className='group-18'>
                <div className='group-16'>
                  <div className='overlap-group-4'>
                    <div className='rectangle-14' />
                    <img
                      className='icon-instance-node-4'
                      alt='Line doughnut'
                      src='https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/65211d62e295b2bd3f0268f3/img/line---doughnut-2.svg'
                    />
                  </div>
                </div>
                <div className='group-19'>
                  <div className='text-wrapper-11'>
                    Conversational Generative Search
                  </div>
                  <p className='text-wrapper-12'>
                    Get concise answers for complex business questions
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};
