import React, { useContext } from 'react';
import './style.css';
import './mobileStyle.css';
import { HeaderNew } from '../HeaderNew';
import { Frame3 } from './frame3';
import { Frame2 } from './frame2';
import { Frame1 } from './frame1';
import { FooterNew } from '../FooterNew';
import ContactNewModal from '../ContactNew';
import { ModalContext } from '../../providers/ModalProvider';

export const NewHomePage = () => {
  const modalProvider = useContext(ModalContext);
  const { open, handleOpen, handleClose } = modalProvider;
  return (
    <>
      <div className='index home-new-page'>
        <div className='width-controller'>
          {/* <div className='width-controller-wrapper'> */}
          <HeaderNew />
          <Frame1 />
          <Frame2 />
          <Frame3 />
          <FooterNew />
          {/* <img
        className='frame-5'
        alt='Frame'
        src='https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/65211d62e295b2bd3f0268f3/img/frame-39892-1@2x.png'
      /> */}
        </div>
        <ContactNewModal {...{ open, handleOpen, handleClose }} />
      </div>
    </>
  );
};
