import React, { Component } from 'react';
// import './index.css';
import { Link } from 'react-router-dom';
import { Box, Grid } from '@mui/material';

export default function FooterNote() {
  return (
    <Box className='footer-note'>
      <p>
        If you are passionate about building innovative technologies that
        fundamentally transform how companies manage their information, please
        send your resume to{' '}
        <a href='mailto:careers@anvai.ai'>careers@anvai.ai</a>.
      </p>
    </Box>
  );
}
