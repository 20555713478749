/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const MenuMoreGridBig1 = ({ className }) => {
  return (
    <svg
      className={`menu-more-grid-big-1 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M22.668 24.0003C22.668 24.7367 23.2649 25.3337 24.0013 25.3337C24.7377 25.3337 25.3346 24.7367 25.3346 24.0003C25.3346 23.2639 24.7377 22.667 24.0013 22.667C23.2649 22.667 22.668 23.2639 22.668 24.0003Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.66667"
      />
      <path
        className="path"
        d="M14.668 24.0003C14.668 24.7367 15.2649 25.3337 16.0013 25.3337C16.7377 25.3337 17.3346 24.7367 17.3346 24.0003C17.3346 23.2639 16.7377 22.667 16.0013 22.667C15.2649 22.667 14.668 23.2639 14.668 24.0003Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.66667"
      />
      <path
        className="path"
        d="M6.66797 24.0003C6.66797 24.7367 7.26492 25.3337 8.0013 25.3337C8.73768 25.3337 9.33464 24.7367 9.33464 24.0003C9.33464 23.2639 8.73768 22.667 8.0013 22.667C7.26492 22.667 6.66797 23.2639 6.66797 24.0003Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.66667"
      />
      <path
        className="path"
        d="M22.668 16.0003C22.668 16.7367 23.2649 17.3337 24.0013 17.3337C24.7377 17.3337 25.3346 16.7367 25.3346 16.0003C25.3346 15.2639 24.7377 14.667 24.0013 14.667C23.2649 14.667 22.668 15.2639 22.668 16.0003Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.66667"
      />
      <path
        className="path"
        d="M14.668 16.0003C14.668 16.7367 15.2649 17.3337 16.0013 17.3337C16.7377 17.3337 17.3346 16.7367 17.3346 16.0003C17.3346 15.2639 16.7377 14.667 16.0013 14.667C15.2649 14.667 14.668 15.2639 14.668 16.0003Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.66667"
      />
      <path
        className="path"
        d="M6.66797 16.0003C6.66797 16.7367 7.26492 17.3337 8.0013 17.3337C8.73768 17.3337 9.33464 16.7367 9.33464 16.0003C9.33464 15.2639 8.73768 14.667 8.0013 14.667C7.26492 14.667 6.66797 15.2639 6.66797 16.0003Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.66667"
      />
      <path
        className="path"
        d="M22.668 8.00033C22.668 8.73671 23.2649 9.33366 24.0013 9.33366C24.7377 9.33366 25.3346 8.73671 25.3346 8.00033C25.3346 7.26395 24.7377 6.66699 24.0013 6.66699C23.2649 6.66699 22.668 7.26395 22.668 8.00033Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.66667"
      />
      <path
        className="path"
        d="M14.668 8.00033C14.668 8.73671 15.2649 9.33366 16.0013 9.33366C16.7377 9.33366 17.3346 8.73671 17.3346 8.00033C17.3346 7.26395 16.7377 6.66699 16.0013 6.66699C15.2649 6.66699 14.668 7.26395 14.668 8.00033Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.66667"
      />
      <path
        className="path"
        d="M6.66797 8.00033C6.66797 8.73671 7.26492 9.33366 8.0013 9.33366C8.73768 9.33366 9.33464 8.73671 9.33464 8.00033C9.33464 7.26395 8.73768 6.66699 8.0013 6.66699C7.26492 6.66699 6.66797 7.26395 6.66797 8.00033Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.66667"
      />
    </svg>
  );
};
