import { memo } from 'react';

const Group39801Icon = (props) => (
  <svg
    preserveAspectRatio='none'
    viewBox='0 0 565 135'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M0 135L52.6003 0H78.9968L131.693 135H101.54L91.2316 107.904H40.2691L30.1536 135H0ZM49.5175 82.35H81.8869L69.652 50.8179C69.2667 49.8536 68.8171 48.7607 68.3033 47.5393C67.7895 46.2536 67.3078 44.9679 66.8582 43.6821C66.4087 42.3964 66.0233 41.2393 65.7022 40.2107C65.3811 41.2393 64.9957 42.3964 64.5461 43.6821C64.0966 44.9679 63.6149 46.2536 63.1011 47.5393C62.5873 48.7607 62.1377 49.8536 61.7524 50.8179L49.5175 82.35Z'
      fill='white'
    />
    <path
      d='M149.835 135V0H175.268L226.231 73.9607C226.873 74.9893 227.804 76.5321 229.024 78.5893C230.245 80.6464 231.176 82.4464 231.818 83.9893C231.626 82.6393 231.465 80.9036 231.336 78.7821C231.272 76.5964 231.24 74.9571 231.24 73.8643V0H258.793V135H234.419L182.397 60.4607C181.691 59.4321 180.727 57.8571 179.507 55.7357C178.351 53.6143 177.42 51.7821 176.713 50.2393C176.97 51.9107 177.098 53.7107 177.098 55.6393C177.163 57.5036 177.195 59.1107 177.195 60.4607V135H149.835Z'
      fill='white'
    />
    <path
      d='M322.908 135L274.547 0H304.508L334.083 86.5929C334.469 87.8143 334.854 89.0036 335.24 90.1607C335.625 91.3179 335.914 92.2821 336.107 93.0536C336.235 92.4107 336.46 91.5107 336.781 90.3536C337.102 89.1964 337.487 87.9429 337.937 86.5929L367.416 0H397.088L348.919 135H322.908Z'
      fill='white'
    />
    <path
      d='M387.42 135L440.02 0H466.417L519.113 135H488.96L478.651 107.904H427.689L417.573 135H387.42ZM436.937 82.35H469.307L457.072 50.8179C456.687 49.8536 456.237 48.7607 455.723 47.5393C455.209 46.2536 454.728 44.9679 454.278 43.6821C453.828 42.3964 453.443 41.2393 453.122 40.2107C452.801 41.2393 452.416 42.3964 451.966 43.6821C451.516 44.9679 451.035 46.2536 450.521 47.5393C450.007 48.7607 449.558 49.8536 449.172 50.8179L436.937 82.35Z'
      fill='white'
    />
    <path d='M537.255 135V0H565V135H537.255Z' fill='white' />
  </svg>
);
const Memo = memo(Group39801Icon);
export { Memo as Group39801Icon };
