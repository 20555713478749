/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const CommunicationChatConversationCircle = ({ className }) => {
  return (
    <svg
      className={`communication-chat-conversation-circle ${className}`}
      fill="none"
      height="40"
      viewBox="0 0 40 40"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M15.5636 26.9841C20.8243 26.6919 25 22.3338 25 17C25 11.4772 20.5228 7 15 7C9.47715 7 5 11.4772 5 17C5 18.9678 5.56823 20.8028 6.54981 22.3499L5.8431 24.4701L5.84197 24.4732C5.57129 25.2852 5.43589 25.6914 5.53231 25.9618C5.61632 26.1974 5.80281 26.3831 6.03841 26.4671C6.30787 26.5632 6.71124 26.4288 7.51782 26.1599L7.5293 26.1564L9.65007 25.4497C11.1972 26.4313 13.0323 26.9997 15.0001 26.9997C15.1892 26.9997 15.3771 26.9944 15.5636 26.9841ZM15.5636 26.9841C15.5637 26.9845 15.5634 26.9837 15.5636 26.9841ZM15.5636 26.9841C16.9318 30.8765 20.6402 33.6669 25.0002 33.6669C26.9681 33.6669 28.8028 33.098 30.3498 32.1164L32.47 32.8231L32.4742 32.8239C33.2861 33.0946 33.6931 33.2302 33.9634 33.1338C34.199 33.0498 34.3831 32.864 34.4671 32.6284C34.5637 32.3577 34.4287 31.951 34.1572 31.1368L33.4505 29.0166L33.6872 28.6243C34.523 27.1632 34.9991 25.4705 34.9991 23.6667C34.9991 18.1438 30.5228 13.6667 25 13.6667L24.6257 13.6736L24.4368 13.6828"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </svg>
  );
};
