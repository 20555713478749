import React, { useContext } from 'react';
import './style.css';
import { ArrowArrowRightMd2 } from '../../../icons/ArrowArrowRightMd2';
import { CommunicationChatConversationCircle } from '../../../icons/CommunicationChatConversationCircle';
import { Box, Grid } from '@mui/material';
import ICON1 from '../../../icons/chart-32.svg';
import ICON2 from '../../../icons/cloud-5.svg';
import ICON3 from '../../../icons/database-3.svg';
import ICON4 from '../../../icons/fishbones-2.svg';
import { EARLY_ACCESS_OPT, LOCATION_TYPE } from '../../../utils/constants';
import { ModalContext } from '../../../providers/ModalProvider';

const GridPartTwo = () => {
  return (
    <div className='frame-wrapper'>
      <div className='frame-6'>
        <div className='group-27'>
          <div className='left-icon-wrapper'>
            <div className='rectangle-17' />
            <img src={ICON4} className='left-icon' />
          </div>
          <div className='group-28'>
            <div className='text-wrapper-15'>
              Radically Simplified Workflows
            </div>
            <p className='text-wrapper-14'>
              Abstracts away the data and AI efforts – alleviates the need for
              complex data and ML pipelines
            </p>
          </div>
        </div>
        <div className='group-27'>
          <div className='left-icon-wrapper'>
            <div className='rectangle-17' />
            <img src={ICON2} className='left-icon' />
          </div>
          <div className='group-28'>
            <div className='text-wrapper-15'>
              Speed up Evaluation and Deployment
            </div>
            <p className='text-wrapper-14'>
              Powerful out of the box capabilities allows faster onboarding and
              quicker deployment
            </p>
          </div>
        </div>
        <div className='group-27'>
          <div className='left-icon-wrapper'>
            <div className='rectangle-17' />
            <img src={ICON3} className='left-icon' />
          </div>
          <div className='group-28'>
            <div className='text-wrapper-15'>
              Iterative Model Development and Incremental addition of Domain
              Knowledge
            </div>
            <p className='text-wrapper-14'>
              Prior domain knowledge can be added to the models incrementally
              allowing for greater precision
            </p>
          </div>
        </div>
        <div className='group-27'>
          <div className='left-icon-wrapper'>
            <div className='rectangle-17' />
            <img src={ICON1} className='left-icon' />
          </div>
          <div className='group-28'>
            <div className='text-wrapper-15'>Flexible Integration</div>
            <p className='text-wrapper-14'>
              API-driven headless architecture leads to flexible and deeper
              integration with business applications &amp; workflows
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Frame2 = () => {
  const modalProvider = useContext(ModalContext);
  const { handleOpen } = modalProvider;
  const handleOnClickOpt = { onClick: () => handleOpen() };
  return (
    <>
      <div className='group-26 frame-2-custom' id={LOCATION_TYPE.product.id}>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            md={4}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <div className='group-32'>
              <div className='group-36'>
                <div className='group-37'>
                  <div className='group-38'>
                    <div className='overlap-group-5'>
                      <div className='rectangle-21' />
                      <CommunicationChatConversationCircle className='communication-chat' />
                    </div>
                  </div>
                  <div className='text-wrapper-19'>Tolka</div>
                  <div className='flexcontainer'>
                    <p className='text'>
                      <span className='span'>
                        Tolka, Anvai&#39;s semantic knowledge discovery platform
                        automates the creation of domain-specific knowledge
                        bases from unstructured data.
                        {/* that can be queried to gain
                        insights, build AI apps, or integrate with business
                        application */}
                      </span>
                    </p>
                    <Box display={{ xs: 'none', md: 'block' }}>
                      <p className='text'>
                        <span className='span'>
                          Knowledge base can be queried to gain insights, build
                          AI apps, or integrate with business application.
                        </span>
                      </p>
                    </Box>
                  </div>
                </div>
              </div>
              <Box
                display={{ md: 'none' }}
                // style={{ display: 'flex', justifyContent: 'center' }}
              >
                <GridPartTwo />
              </Box>
              <div className='group-33'>
                <div className='rectangle-18' />
                {/* <div className='overlap-12'> */}
                <div className='custom-flex'>
                  {/* <div> */}
                  <div className='text-wrapper-18' {...handleOnClickOpt}>
                    Early Access
                  </div>
                  <div className=''>
                    <div className='overlap-group-4'>
                      <div className='rectangle-20' />
                      <ArrowArrowRightMd2 className='arrow-arrow-right-md-2' />
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </Grid>
          <Box
            component={Grid}
            item
            xs={12}
            md={8}
            // style={{ display: 'flex', justifyContent: 'center' }}
            display={{ xs: 'none', md: 'block' }}
          >
            <GridPartTwo />
          </Box>
        </Grid>
      </div>
    </>
  );
};
