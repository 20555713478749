/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const NavigationBuilding01 = ({ className }) => {
  return (
    <svg
      className={`navigation-building-01 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M2.66602 26.6663H5.33268M5.33268 26.6663H11.9993M5.33268 26.6663V9.59994C5.33268 8.10646 5.33268 7.35917 5.62333 6.78874C5.87899 6.28697 6.28665 5.87932 6.78841 5.62366C7.35884 5.33301 8.10614 5.33301 9.59961 5.33301H11.7329C13.2264 5.33301 13.9721 5.33301 14.5425 5.62366C15.0443 5.87932 15.4533 6.28697 15.709 6.78874C15.9993 7.35861 15.9993 8.105 15.9993 9.59555V13.3333M11.9993 26.6663H26.666M11.9993 26.6663V19.1572C11.9993 18.4567 11.9993 18.1063 12.0833 17.7796C12.1578 17.4901 12.2803 17.2155 12.446 16.9666C12.6328 16.6859 12.8942 16.4509 13.4147 15.9833L16.4835 13.2263C17.4898 12.3222 17.9933 11.8699 18.5627 11.6982C19.0646 11.5469 19.6003 11.5469 20.1022 11.6982C20.672 11.87 21.1762 12.3226 22.1842 13.2282L25.2509 15.9833C25.772 16.4514 26.032 16.6858 26.2189 16.9666C26.3846 17.2155 26.5069 17.4901 26.5814 17.7796C26.6654 18.1063 26.666 18.4567 26.666 19.1572V26.6663M26.666 26.6663H29.3327"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </svg>
  );
};
