import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'typeface-montserrat';
import 'typeface-poppins';
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyB5zh4kwD7hKSzpdQM0_lW83pxuunksAhI',
  authDomain: 'anvai-ai-website.firebaseapp.com',
  projectId: 'anvai-ai-website',
  storageBucket: 'anvai-ai-website.appspot.com',
  messagingSenderId: '384001700644',
  appId: '1:384001700644:web:a7c3b939605e3af9e179b1',
  measurementId: 'G-T0Z0XN0Z5S',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
