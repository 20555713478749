import { Box } from '@mui/material';
import React, { Component } from 'react';
// import './index.css';
import { Link } from 'react-router-dom';
import OpenPositions from './OpenPosition';
import Section1 from './section1/section1';

export default function Career() {
  return (
    <Box className='page-wrapper'>
      <Section1 />
      <Box className='sub-sections'>
        <OpenPositions />
      </Box>
    </Box>
  );
}
