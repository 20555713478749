import React from 'react';

export const MenuAlt1 = ({ className }) => {
  return (
    <svg
      className={`${className}`}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 17H19M5 12H19M5 7H19'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
