import React, { Component } from 'react';
import './index.css';
import { Link, useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import ImgSrc from './anvaiLogo.png';
import useRequestDemo from '../../hooks/useRequestDemo';

function Header() {
  const navigate = useNavigate();
  const { handleClick } = useRequestDemo();
  return (
    <>
      <Grid
        container
        style={{
          padding: '14px 140px',
          display: 'flex',
          alignItems: 'center',
          //   position: 'fixed',
        }}
      >
        <Grid item xs={2}>
          <div
            style={{ textAlign: 'left', cursor: 'pointer' }}
            onClick={() => navigate('/')}
          >
            <img src={ImgSrc} style={{ width: '70px' }} />
          </div>
        </Grid>
        <Grid item xs={10}>
          <div className='v19_95'>
            {/* <Link to='/'> */}
            <span
              className='v19_96'
              onClick={() => {
                navigate('/');
                document
                  ?.getElementById('tolka-platform-wrapper')
                  ?.scrollIntoView?.();
              }}
            >
              Tolka Platform
            </span>
            {/* </Link> */}
            <Link to='/use-cases'>
              <span className='v19_96'>Use Cases</span>
            </Link>

            <Link to='/about'>
              <span className='v19_97'>About</span>
            </Link>

            <Link to='/blog'>
              <span className='v162_864'>Blogs</span>
            </Link>

            <Link to='/career'>
              <span className='v19_98'>Careers</span>
            </Link>
            <Link to='/contact'>
              <span className='v19_98'>Contact</span>
            </Link>

            <div className='v19_99' onClick={handleClick}>
              <span className='v19_100'>Request Demo</span>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default Header;
