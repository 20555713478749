import React from 'react';
import './style.css';

export default function YoutubeEmbed({ embedId }) {
  console.log('window.innerWidth', window.innerWidth);
  console.log('window.innerHeight', window.innerHeight);
  return (
    <div className='video-responsive'>
      <iframe
        width={window.innerWidth || '853'}
        height={window.innerHeight || '480'}
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        title='Embedded youtube'
      />
    </div>
  );
}
