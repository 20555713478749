import React, { Component } from 'react';
import './index.css';
import { Link, useNavigate } from 'react-router-dom';
import { Grid, Box, Typography } from '@mui/material';
import ImgSrc from '../Blog.jpg';

export default function Section1() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/blog/full');
  };
  return (
    <Box className='section-grid-wrapper'>
      <Box style={{ cursor: 'pointer' }} onClick={handleClick}>
        <Box style={{ marginBottom: 40 }}>
          <span className='v133_373'>Featured</span>
        </Box>
        <Box className='single-blog-wrapper'>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <img src={ImgSrc} style={{ width: '95%', height: '322px' }} />
            </Grid>
            <Grid item xs={12} md={8}>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 20,
                }}
              >
                <Box flex={3}>
                  <span className='v133_380'>5 min read</span>
                  <div className='v133_381'>
                    Exploring ESG Use Cases with Tolka
                  </div>

                  <div className='v133_382'>
                    Environmental, Social, and Governance (ESG) factors are
                    increasingly relevant in investing, operational, and
                    business decisions. However, quality and traceability remain
                    critical challenges for ESG data, along with a lack of
                    global regulation in the space. However, emerging
                    capabilities in AI and NLP technology have the potential to
                    provide new data points and fresh ...
                  </div>
                </Box>
                <Box flex={1}>
                  <div className='v133_383'>Oct 11, 2022</div>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
