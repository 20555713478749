import { memo, useContext } from 'react';

import { Group39801Icon } from './Group39801Icon';
import { Group39906Icon } from './Group39906Icon';
import { ArrowArrowRightMd } from '../../assets/icons/ArrowArrowRightMd';
import './_resets.module.css';
import './style.css';
import BGIMG from '../../icons/Group 39906.png';
import BGIMG2 from '../../icons/Group-39906.png';
import { Box } from '@mui/material';
import {
  EARLY_ACCESS_OPT,
  LOCATION_TYPE,
  setWindowLocation,
} from '../../utils/constants';
import { useNavigate } from 'react-router';
import { ModalContext } from '../../providers/ModalProvider';

/* @figmaId 290:123 */
export const FooterNew = memo(function Frame39780(props = {}) {
  const navigate = useNavigate();
  const modalProvider = useContext(ModalContext);
  const { handleOpen } = modalProvider;
  const handleOnClickOpt = { onClick: () => handleOpen() };
  return (
    <Box
      className='storybrainResets root footer-new'
      style={
        {
          // backgroundImage: `url('${BGIMG2}')`,
        }
      }
      display={{ md: 'block' }}
    >
      {/* backgroundColor: 'white' */}
      <div className='group39819'>
        <div className='group39801'>
          <Group39801Icon className='icon2' />
        </div>
        <div className='tapUnstructuredDataToMaximizeB'>
          Tap unstructured data to maximize business performance
        </div>
        <div className='footer-custom-flex'>
          <div className='early-access-wrapper'>
            <div className='earlyAccess' {...handleOnClickOpt}>
              Early Access
            </div>
            <div className='arrow-wrapper'>
              <ArrowArrowRightMd className='arrowArrow_Right_MD' />
            </div>
          </div>
          <div className='footer-custom-flex-2'>
            <div className='frame39763'>
              <div
                className='product'
                onClick={() => {
                  setWindowLocation(LOCATION_TYPE.product.goto);
                }}
              >
                Product
              </div>
              <div
                className='useCases'
                onClick={() => {
                  setWindowLocation(LOCATION_TYPE.usecase.goto);
                }}
              >
                Use Cases
              </div>
              <div className='aboutUs' onClick={() => navigate('/about')}>
                About Us
              </div>
            </div>
            <div className='frame39764'>
              <div className='linkedIn'>LinkedIn</div>
              <div className='twitter'>Twitter</div>
            </div>
          </div>
        </div>
        <div className='footer-2-wrapper'>
          <div className='AnvaiInc'>© Anvai, Inc.</div>
          <div className='frame39888'>
            <div className='privacy'>Privacy</div>
            <div className='terms'>Terms</div>
          </div>
        </div>
        {/* <div className='group39906'>
          <Group39906Icon className='icon' />
        </div> */}
        {/*
         */}
      </div>
    </Box>
  );
});
