import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import './index.css';
import { BACK_URL, COMPANY_URL, COUNTRY_CODE } from '../../utils/constants';
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const ControllerWrapper = ({ control, name, label, multiline }) => {
  const obj = multiline
    ? {
        placeholder: 'Write your message',
        multiline: true,
        rows: 3,
        variant: 'outlined',
      }
    : {};
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value, ref },
        fieldState: { isTouched, isDirty, error },
      }) => (
        <>
          {/* {console.log('error,isTouched,isDirty', error, isTouched, isDirty)} */}
          <TextField
            onChange={onChange}
            value={value}
            label={label}
            fullWidth
            variant='standard'
            required
            inputRef={ref}
            {...obj}
          />
        </>
      )}
    />
  );
};

const DEFAULT_VAL = {
  name: '',
  job_title: '',
  email_address: '',
  company_name: '',
  country_code: '',
  phone_no: '',
  inquiry_details: '',
};
export default function Contact() {
  const { handleSubmit, reset, control } = useForm({
    shouldUseNativeValidation: true,
    defaultValues: DEFAULT_VAL,
  });
  const [sentState, setSentState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async (data) => {
    console.log(data);
    const OBJ = {
      name: 'Name',
      job_title: 'Job Title',
      email_address: 'Email Address',
      company_name: 'Company Name',
      country_code: 'Country Code',
      phone_no: 'Phone No',
      inquiry_details: 'Inquiry Details',
    };
    let errObj = null;
    for (let key of Object.keys(data)) {
      if (data[key] == undefined || data[key] == '') {
        errObj = key;
        break;
      }
    }
    if (errObj !== null) {
      alert(`${OBJ[errObj]} is required`);
      return;
    }
    try {
      setIsLoading(() => true);
      const respo = await axios.post(`${BACK_URL}/api/contact_us`, data);
      console.log('respo', respo);
      reset(DEFAULT_VAL);
      setSentState(() => true);
      // alert('We have received you inquiry.');
    } catch (errorResp) {
      console.log('errorResp', errorResp);
      setIsLoading(() => false);
      const message = errorResp.message;
      alert(message);
    }
  };
  return (
    <Box className='page-wrapper'>
      <Box className='section-grid-wrapper'>
        <Grid container>
          <Grid item xs={12}>
            <div className='section-heading-wrapper'>
              <>
                <div className='section-heading-title'>Contact Us</div>
                <div className='section-heading-subtitle'>
                  Any questions or remarks? Just write us a message.
                </div>
              </>
            </div>
          </Grid>
        </Grid>
        <Grid container className='contact-grid-container'>
          <Box
            component={Grid}
            item
            md={4}
            xs={12}
            style={{
              backgroundColor: '#1c9ce0',
              borderRadius: '10px',
            }}
            display={{ xs: 'none', md: 'block' }}
          >
            <div className='contact-info-wrapper'>
              <div className='top-header'>
                <div className='top-header-title'>Contact Information</div>
                <div className='top-header-subtitle'>
                  Please reach out to us if you’d like a free demo or simply
                  discuss how Anvai can help your company. You can email us at{' '}
                  <a href='mailto:solutions@anvai.ai'>solutions@anvai.ai</a> or
                  provide some information here so we can contact you.
                </div>
              </div>
              <div className='social-media-box'>
                <div className='social-media-item'>
                  {/* Follow us on
                  <br />
                  <LinkedInIcon style={{ fontSize: 22, color: '#blue' }} /> */}
                  <div>
                    <LinkedInIcon
                      onClick={(e) => window.open(COMPANY_URL, '_blank')}
                    />
                    <a href={COMPANY_URL} target='_blank'>
                      {COMPANY_URL}
                    </a>
                  </div>
                </div>
              </div>
              <div className='contact-info'></div>
            </div>
          </Box>
          <Grid item md={6} xs={12}>
            <Box className='contact-box-wrapper'>
              <Box className='form-box-wrapper'>
                <Box className='form-row'>
                  <div className='form-row-item'>
                    <ControllerWrapper
                      control={control}
                      name='name'
                      label={'Name'}
                    />
                  </div>
                  <div className='form-row-item'>
                    <ControllerWrapper
                      control={control}
                      name='job_title'
                      label={'Job Title'}
                    />
                  </div>
                </Box>
                <Box className='form-row'>
                  <div className='form-row-item'>
                    <ControllerWrapper
                      control={control}
                      name='email_address'
                      label={'Email Address'}
                    />
                  </div>
                  <div className='form-row-item'>
                    <ControllerWrapper
                      control={control}
                      name='company_name'
                      label={'Company Name'}
                    />
                  </div>
                </Box>
                <Box className='form-row'>
                  <div className='form-row-item'>
                    <Controller
                      control={control}
                      name={'country_code'}
                      render={({ field: { onChange, value, ref } }) => (
                        <TextField
                          select
                          label='Country'
                          variant='standard'
                          fullWidth
                          value={value}
                          onChange={onChange}
                          // inputRef={ref}
                          helperText='Please select your country'
                        >
                          {COUNTRY_CODE.map((option) => (
                            <MenuItem key={option.code} value={option.code}>
                              {option.country} (+{option.code})
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </div>
                  <div className='form-row-item'>
                    <ControllerWrapper
                      control={control}
                      name='phone_no'
                      label={'Phone No'}
                    />
                  </div>
                </Box>
                <Box className='form-row'>
                  <div className='form-row-item'>
                    <ControllerWrapper
                      control={control}
                      name='inquiry_details'
                      label={'Inquiry Detail'}
                      multiline={true}
                    />
                  </div>
                </Box>

                <Box className='form-row'>
                  <div className='form-row-item'>
                    {sentState ? (
                      <Button variant='contained' disabled>
                        Inquiry Received
                      </Button>
                    ) : (
                      <>
                        {isLoading ? (
                          <CircularProgress />
                        ) : (
                          <Button
                            variant='contained'
                            onClick={handleSubmit(onSubmit)}
                          >
                            Submit
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        component={Grid}
        item
        style={{
          backgroundColor: '#1c9ce0',
          marginBottom: 10,
        }}
        display={{ xs: 'block', md: 'none' }}
      >
        <div className='contact-info-wrapper'>
          <div className='top-header'>
            <div className='top-header-title'>Contact Information</div>
            <div className='top-header-subtitle'>
              Whether you want to sign up for the demo or simply have a question
              we are here to help. Please provide us some information about you
              and how we can reach you below and we will contact you shortly
            </div>
          </div>
        </div>
      </Box>
    </Box>
  );
}
