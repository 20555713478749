import React, { Component, useEffect, useLayoutEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
// import About from './components/about.js';
import About from './views/about';
// import Header from './components/header.js';
import Footer from './views/footer';
import Blog from './views/blog';
import Home from './views/home';
import Header from './views/header';
import BlogFull from './views/blog_full';
import Navbar from './views/header/navbar';
import { Box } from '@mui/material';
import MobFooter from './views/footer/mob-index';
import Contact from './views/contact';
import UseCases from './views/home/section3';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import Career from './views/career';
import JobFull from './views/career/JobFull';
import { NewHomePage } from './screens/Index/Index.jsx';
import { AboutNewPage } from './screens/AboutNew';
import ModalProvider from './providers/ModalProvider';
import EmbeddedVideos from './views/youtubeEmbed/index.js';

function App() {
  const goToTop = () => {
    document?.getElementById('header-container')?.scrollIntoView?.();
  };

  useEffect(() => {
    let requestAnimationFrame =
      window.requestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.msRequestAnimationFrame;
    const eventListener = document.addEventListener(
      'DOMContentLoaded',
      function () {
        const goTopButton = document.getElementById('top-arrow');
        const windowViewPortHeight = window.innerHeight / 2; // browser viewport height
        let isRequestingAnimationFrame = false;
        if (!goTopButton) {
          console.log('goTopButton', 'goTopButton not found');
          return;
        }

        goTopButton.addEventListener('click', function () {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        });

        window.addEventListener('scroll', function () {
          if (!isRequestingAnimationFrame) {
            requestAnimationFrame(filterGoTopButtonVisibility);
            isRequestingAnimationFrame = true;
          }
        });

        function filterGoTopButtonVisibility(timestamp) {
          let windowPageYOffset =
            window.pageYOffset || document.documentElement.scrollTop;
          if (windowPageYOffset > windowViewPortHeight) {
            // goTopButton.classList.remove('hideit');
            goTopButton.classList.add('show');
            isRequestingAnimationFrame = false;
          } else {
            // goTopButton.classList.add('hideit');
            goTopButton.classList.remove('show');
            requestAnimationFrame(filterGoTopButtonVisibility);
          }
        }
      }
    );
    return () => eventListener;
  }, []);

  return (
    <>
      <ModalProvider>
        <Router>
          {/* <div className='App' id='header-container'>
          <Box display={{ xs: 'none', md: 'block' }}>
            <Header />
          </Box>
          <Box display={{ xs: 'block', md: 'none' }}>
            <Navbar />
          </Box>
        </div> */}
          <Routes>
            {/* <Route path='/' element={<Home />} /> */}
            {/* <Route path='/about' element={<About />} /> */}
            <Route path='/blog' element={<Blog />} />
            <Route path='/blog/full' element={<BlogFull />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/use-cases' element={<UseCases />} />
            <Route path='/career/full/:id' element={<JobFull />} />
            <Route path='/career' element={<Career />} />
            <Route path='/' element={<NewHomePage />} />
            <Route path='/about' element={<AboutNewPage />} />
            <Route path='/demo-video' element={<EmbeddedVideos />} />
            <Route path='/demo-video/:id' element={<EmbeddedVideos />} />
          </Routes>
          {/* <div id='top-arrow' className=''>
          <ArrowCircleUpIcon
            style={{ fontSize: '40px', color: 'blue', position: 'fixed' }}
          />
        </div> */}
          {/* <Box display={{ xs: 'none', md: 'block' }}>
          <Footer />
        </Box>
        <Box display={{ xs: 'block', md: 'none' }}>
          <MobFooter />
        </Box> */}
        </Router>
      </ModalProvider>
    </>
  );
}

export default App;
