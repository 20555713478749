import { Box, Grid } from '@mui/material';
import React from 'react';
import './index.css';
import ImgSrc from './img1.jpg';
import LabelSharpIcon from '@mui/icons-material/LabelSharp';

export default function Part1() {
  return (
    <>
      <Grid container className='use-case-grid-container'>
        <Grid item md={6} xs={12} style={{ paddingTop: 10 }}>
          <img src={ImgSrc} className='use-case-img' />
        </Grid>
        <Grid item md={6} xs={12}>
          <div className='v9_376'>
            <div className='v8_323'>Managing Sales Pipeline</div>
            <div className='v9_375'>
              <div className='v9_370'>
                <Box className='boxWrapper'>
                  <div className='v9_369'>
                    {/* <img src={ImgSrc1} /> */}
                    <LabelSharpIcon />
                  </div>
                  <div className='v8_365'>
                    Identify Emerging Risks
                    <br />
                    <span>
                      Why did we lose our major clients to our closest
                      competitor?
                    </span>
                  </div>
                </Box>
              </div>
              <div className='v9_370'>
                <Box className='boxWrapper'>
                  <div className='v9_369'>
                    {/* <img src={ImgSrc2} /> */}
                    <LabelSharpIcon />
                  </div>
                  <div className='v8_365'>
                    Obtain Insights
                    <br />
                    <span>
                      What are the top three things we can do to improve our
                      falling customer engagement?
                    </span>
                  </div>
                </Box>
              </div>
              <div className='v9_370'>
                <Box className='boxWrapper'>
                  <div className='v9_369'>
                    {/* <img src={ImgSrc3} /> */}
                    <LabelSharpIcon />
                  </div>
                  <div className='v8_365'>
                    Compare and Contrast
                    <br />
                    <span>
                      What is our rate of innovation when it comes to our
                      industry peers? Are we known for being innovative?
                    </span>
                  </div>
                </Box>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
