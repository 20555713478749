import { Box, Grid } from '@mui/material';
import React from 'react';
import './index.css';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { JOB_OBJECTS } from '../../../utils/constants';
import { useNavigate } from 'react-router';

export default function OpenPositions() {
  const JOBS = [
    { title: 'Senior MLOps Engineer', location: 'Remote/San Jose, CA' },
  ];
  const navigate = useNavigate();
  return (
    <Box
      className='section-grid-wrapper'
      id='open-position-box'
      style={{ backgroundColor: '#F5FBFF', paddingBottom: '50px' }}
    >
      <Grid container>
        <Grid item xs={12}>
          <div className='v162_853'>
            <>
              <div className='section-heading-subtitle'>Open Positions</div>
              <div className='applyvia-box'>
                Apply via <a href='mailto:careers@anvai.ai'>careers@anvai.ai</a>
              </div>
            </>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Box className='position-wrapper'>
            <Grid container spacing={4}>
              {Object.keys(JOB_OBJECTS).map((item, idx) => {
                const { title2, location } = JOB_OBJECTS[item];
                return (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      key={idx}
                      onClick={(e) => navigate(`/career/full/${item}`)}
                    >
                      <Box className='job-display'>
                        <Box className='job-display-title'>{title2}</Box>
                        <Box className='job-location'>
                          <Box className='icon'>
                            <PlaceOutlinedIcon />
                          </Box>
                          <Box className='location-info'>{location}</Box>
                        </Box>
                      </Box>
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Box>
        </Grid>
        {/* <Grid item xs={12} md={4}>
          <div className='v5_48'>
            <div className='v5_47'>
              <span className='v5_42'>
                <img src={ImgSrc4} />
              </span>
              <span className='v5_44'>Low Carbon Footprint</span>
            </div>
            <div className='v5_46'>
              Our deep-learning based proprietary algorithmic innovations
              provide insights and answer user queries with human-like precision
              but at scale.
            </div>
          </div>
        </Grid>

        <Grid item xs={12} md={4}>
          <div className='v5_48'>
            <div className='v5_47'>
              <span className='v5_42'>
                <img src={ImgSrc5} />
              </span>
              <span className='v5_44'>Low-code Solutions</span>
            </div>
            <div className='v5_46'>
              Our deep-learning based proprietary algorithmic innovations
              provide insights and answer user queries with human-like precision
              but at scale.
            </div>
          </div>
        </Grid> */}
      </Grid>
    </Box>
  );
}
