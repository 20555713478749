import { Box, Grid } from '@mui/material';
import React from 'react';
import './index.css';
import ImgSrc from './img2.jpg';
import LabelSharpIcon from '@mui/icons-material/LabelSharp';

export default function Part2() {
  return (
    <>
      <Grid container style={{ padding: '16px 10px' }}>
        <Box
          component={Grid}
          item
          md={6}
          xs={12}
          display={{ xs: 'block', md: 'none' }}
        >
          <img src={ImgSrc} className='use-case-img' />
        </Box>
        <Box
          component={Grid}
          item
          md={6}
          xs={12}
          display={{ xs: 'none', md: 'block' }}
        >
          <img src={ImgSrc} className='use-case-img' />
        </Box>
        <Grid item xs={12} md={6} style={{ paddingRight: 30 }}>
          <div className='v9_376'>
            <div className='v8_323'>Collecting & Measuring ESG Data</div>
            <div className='v9_375'>
              <div className='v9_370'>
                <Box className='boxWrapper'>
                  <div className='v9_369'>
                    <LabelSharpIcon />
                  </div>
                  <div className='v8_365'>
                    Identify Emerging Risks
                    <br />
                    <span>
                      Is there a material risk for this client from the human
                      trafficking issues with their supplier in SE Asia?
                    </span>
                  </div>
                </Box>
              </div>
              <div className='v9_370'>
                <Box className='boxWrapper'>
                  <div className='v9_369'>
                    <LabelSharpIcon />
                  </div>
                  <div className='v8_365'>
                    Obtain Insights
                    <br />
                    <span>
                      What is the representation of women and other minority
                      groups in this new client’s Board of Directors?
                    </span>
                  </div>
                </Box>
              </div>
              <div className='v9_370'>
                <Box className='boxWrapper'>
                  <div className='v9_369'>
                    <LabelSharpIcon />
                  </div>
                  <div className='v8_365'>
                    Compare and Contrast
                    <br />
                    <span>
                      Compare and Contrast How are our key ESG ratings companies
                      measuring this client’s performance?
                    </span>
                  </div>
                </Box>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
