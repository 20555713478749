import React, { Component, useEffect } from 'react';
import './index.css';
import { Link, useParams } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import CompanyDetails from './CompanyDetails';
import FooterNote from './FooterNote';
import { JOB_OBJECTS } from '../../../utils/constants';
export default function JobFull() {
  const { id } = useParams();
  const dataObj = JOB_OBJECTS[id || 1];
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
  return (
    <Box className='page-wrapper'>
      <Box className='section-grid-wrapper'>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CompanyDetails dataObj={dataObj} />
            {dataObj.sections.map((item, idx) => {
              const { title, value } = item;
              return (
                <Box className='section' key={idx}>
                  <Box className='section-title'>{title}:</Box>
                  <Box className='section-content'>
                    <ul>
                      {value.map((inItem, idx2) => (
                        <li key={idx2}>{inItem}</li>
                      ))}
                    </ul>
                  </Box>
                </Box>
              );
            })}
            <FooterNote />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
