import React from 'react';
import './style.css';
import { InterfaceSettingsFuture } from '../../../assets/icons/InterfaceSettingsFuture';
import { EnvironmentRainbow } from '../../../icons/EnvironmentRainbow';
import { Box } from '@mui/material';
import SVG1 from '../../../icons/Vector-27.svg';
import SVG2 from '../../../icons/Vector-28.svg';
import PNG1 from '../../../icons/Vector-27.png';
import RECTIMG from '../../../icons/rect.png';
import RECTIMG1 from '../../../icons/rect1.png';
import RECTIMG2 from '../../../icons/rect2.png';
import RECTIMG3 from '../../../icons/rect3.png';
import RECTIMG4 from '../../../icons/rect4.png';
import { LOCATION_TYPE } from '../../../utils/constants';

export const Frame3 = () => {
  return (
    <>
      <div className='group-39 frame-3-custom' id={LOCATION_TYPE.usecase.id}>
        <div className='overlap-13'>
          <Box display={{ xs: 'none', md: 'block' }}>
            <img
              className='group-40'
              alt='Group'
              src='https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/65211d62e295b2bd3f0268f3/img/group-39799@2x.png'
            />
            <img
              className='vector-7'
              alt='Vector'
              src='https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/65211d62e295b2bd3f0268f3/img/vector-27.svg'
            />
            <img
              className='vector-8'
              alt='Vector'
              src='https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/6513c9bb47fa9724c4d455f4/img/vector-28.svg'
            />
          </Box>
          <Box display={{ md: 'none' }}>
            <img className='vector-7' alt='Vector' src={PNG1} />
            <img className='vector-8' alt='Vector' src={SVG2} />
          </Box>
          <div className='group-47'>
            <div>
              <InterfaceSettingsFuture className='icon-instance-node-5' />
            </div>
            <div className='use-cases'>USE CASES</div>
          </div>
          <p className='text-wrapper-20'>Customizable to your Use Case</p>
          <div className='frame-7'>
            <div className='frame-8'>
              <div className='group-41'>
                <div className='rectangle-22' />
                <div className='text-wrapper-21'>Claims Processing</div>
              </div>
            </div>
            <div className='frame-8'>
              <div className='group-41'>
                <div className='rectangle-22' />
                <div className='text-wrapper-21'>Market Intelligence</div>
              </div>
            </div>
            <div className='frame-8'>
              <div className='group-41'>
                <div className='rectangle-22' />
                <div className='text-wrapper-21'>Investment Research</div>
              </div>
            </div>
            <div className='frame-8'>
              <div className='group-41'>
                <div className='rectangle-22' />
                <div className='text-wrapper-21'>
                  Legal And Regulatory Compliance
                </div>
              </div>
            </div>
            <div className='frame-8'>
              <div className='group-41'>
                <div className='rectangle-22' />
                <div className='text-wrapper-21'>Product Discovery</div>
              </div>
            </div>
            <div className='frame-8'>
              <div className='group-41'>
                <div className='rectangle-22' />
                <div className='text-wrapper-21'>Customer Insights</div>
              </div>
            </div>
          </div>
          <div className='frame-9'>
            <div className='group-48'>
              <EnvironmentRainbow className='icon-instance-node-5' />
              <div className='text-wrapper-22'>INDUSTRIES</div>
            </div>
            <div className='group-49'>
              <div className='frame-10'>
                <img className='rectangle-23' alt='Vector' src={RECTIMG} />
                <div className='text-wrapper-23'>Asset Management</div>
              </div>
            </div>
            <div className='group-49'>
              <div className='frame-10'>
                <img className='rectangle-23' alt='Vector' src={RECTIMG1} />
                <div className='text-wrapper-23'>Banking</div>
              </div>
            </div>
            <div className='group-49'>
              <div className='frame-10'>
                <img className='rectangle-23' alt='Vector' src={RECTIMG2} />
                <div className='text-wrapper-23'>Insurance</div>
              </div>
            </div>
            <div className='group-49'>
              <div className='frame-10'>
                <img className='rectangle-23' alt='Vector' src={RECTIMG3} />
                <div className='text-wrapper-23'>Retail</div>
              </div>
            </div>
            <div className='group-49'>
              <div className='frame-10'>
                <img className='rectangle-23' alt='Vector' src={RECTIMG4} />
                <div className='text-wrapper-23'>Pharma &amp; Biotech</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
