import React, { useEffect } from 'react';
import { NavigationBuilding } from '../../components/NavigationBuilding';
import { NavigationCompassWrapper } from '../../components/NavigationCompassWrapper';
import { ArrowArrowRightMd } from '../../icons/ArrowArrowRightMd';
import { IconLinkedin } from '../../icons/IconLinkedin';
import { NavigationBuilding01 } from '../../icons/NavigationBuilding01';
import './style.css';
import { HeaderNew } from '../HeaderNew';
import { Box } from '@mui/material';

export const AboutNewPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
  return (
    <div className='index about-new-page-wrapper'>
      <div className='width-controller'>
        {/* <div className='width-controller-wrapper'> */}
        <HeaderNew />
        <Box className='overall-wrapper about-new-page'>
          <div className='overlap'>
            <div className='overlap-2'>
              <div className='text-wrapper-3'>About Us</div>
              <div className='custom-box-wrapper'>
                <Box display={{ xs: 'none', md: 'block' }}>
                  <div className='group-2'>
                    <NavigationCompassWrapper />
                    <div className='mission'>MISSION</div>
                  </div>
                </Box>
                <div className='flexcontainer'>
                  <p className='text'>
                    <span className='span'>
                      Anvai was founded in 2021 with a mission to help
                      enterprises tap unstructured data to maximize their
                      business performance.
                      <br />
                    </span>
                  </p>
                  <p className='text'>
                    <span className='span'>
                      The enduring vision which guides us to date is to automate
                      the deep semantic enrichment of the unstructured text
                      available from data sources within the enterprise,
                      publicly or from third party&nbsp;&nbsp;and making it
                      readily available as a domain specific knowledge base for
                      business users to gain actionable insights from and for
                      enterprise teams to build custom AI applications quickly
                      and with fewer resources.
                      <br />
                    </span>
                  </p>
                  <p className='text'>
                    <span className='span'>
                      The founding team has decades of experience delivering
                      innovative AI solutions at scale for Fortune 50 and some
                      of the top technology companies in the world.
                    </span>
                  </p>
                </div>
              </div>
              {/* <div className='rectangle-2' /> */}
            </div>
            <div className='group-3'>
              <div className='team-wrapper'>
                <div className='group-10'>
                  <NavigationBuilding />
                  <div className='text-wrapper-7'>TEAM</div>
                </div>
                <div className='person-wrapper'>
                  <div className='group-6 person-box'>
                    <img
                      className='img-2 person-img'
                      alt='Img'
                      src='https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/6521a2423c4e5cc985cd2c09/img/img-0014-1@2x.png'
                    />
                    <div className='group-wrapper'>
                      <div className='div-wrapper'>
                        <div className='group-7'>
                          <div className='overlap-group-2'>
                            <div className='text-wrapper-5'>
                              Ramasubbu “Venky” Venkatesh
                              <IconLinkedin
                                className='icon-linkedin-5'
                                color='#5DA4EF'
                              />
                            </div>
                          </div>
                          <div className='co-founder-CEO'>
                            Co-Founder &amp; CEO
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='group-6 person-box'>
                    <img
                      className='img-2 person-img'
                      alt='Img'
                      src='https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/651536b38ee3c1d4abd029f0/img/rectangle-760@2x.png'
                    />
                    <div className='group-wrapper'>
                      <div className='div-wrapper'>
                        <div className='group-7'>
                          <div className='overlap-group-2'>
                            <div className='text-wrapper-5'>
                              Srikanth Ryali
                              <IconLinkedin
                                className='icon-linkedin-5'
                                color='#5DA4EF'
                              />
                            </div>
                          </div>
                          <div className='co-founder-CEO'>
                            Co-Founder &amp; <br />
                            Chief Scientist
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='group-6 person-box'>
                    <img
                      className='img-2 person-img'
                      alt='Img'
                      src='https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/651536b38ee3c1d4abd029f0/img/rectangle-761@2x.png'
                    />
                    <div className='group-wrapper'>
                      <div className='div-wrapper'>
                        <div className='group-7'>
                          <div className='overlap-group-2'>
                            <div className='text-wrapper-5'>
                              Pramod Lakshmi Narasimha{' '}
                              <IconLinkedin
                                className='icon-linkedin-5'
                                color='#5DA4EF'
                              />
                            </div>
                          </div>
                          <div className='co-founder-CEO'>
                            Founding Engineer &amp; Head of ML
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='team-wrapper'>
                <div className='group-10'>
                  <NavigationBuilding />
                  <div className='text-wrapper-7'>ADVISORS</div>
                </div>
                <div className='person-wrapper'>
                  <div className='group-6 person-box'>
                    <img
                      className='img-2 person-img'
                      alt='Img'
                      src='https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/6521a2423c4e5cc985cd2c09/img/image-28@2x.png'
                    />
                    <div className='group-wrapper'>
                      <div className='div-wrapper'>
                        <div className='group-7'>
                          <div className='overlap-group-2'>
                            <div className='text-wrapper-5'>
                              Balasubramanyam Mohan
                              <IconLinkedin
                                className='icon-linkedin-5'
                                color='#5DA4EF'
                              />
                            </div>
                          </div>
                          <div className='co-founder-CEO'>
                            Managing Director, Moxo
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='group-6 person-box'>
                    <img
                      className='img-2 person-img'
                      alt='Img'
                      src='https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/6521a2423c4e5cc985cd2c09/img/image-29@2x.png'
                    />
                    <div className='group-wrapper'>
                      <div className='div-wrapper'>
                        <div className='group-7'>
                          <div className='overlap-group-2'>
                            <div className='text-wrapper-5'>
                              Srijan Kumar
                              <IconLinkedin
                                className='icon-linkedin-5'
                                color='#5DA4EF'
                              />
                            </div>
                          </div>
                          <div className='co-founder-CEO'>
                            Professor, Georgia Institute of Technology
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className='group-6 person-box'>
                    <img
                      className='img-2 person-img'
                      alt='Img'
                      src='https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/651536b38ee3c1d4abd029f0/img/rectangle-761@2x.png'
                    />
                    <div className='group-wrapper'>
                      <div className='div-wrapper'>
                        <div className='group-7'>
                          <div className='overlap-group-2'>
                            <div className='text-wrapper-5'>
                              Pramod Lakshmi Narasimha{' '}
                              <IconLinkedin
                                className='icon-linkedin-5'
                                color='#5DA4EF'
                              />
                            </div>
                          </div>
                          <div className='co-founder-CEO'>
                            Founding Engineer &amp; Head of ML
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              {/* <div className='advisor-wrapper'>
                <div className='group-11'>
                  <div className='text-wrapper-7'>ADVISORS</div>
                  <NavigationBuilding01 className='navigation-building' />
                </div>
                <div className='group-12'>
                  <div className='group-13'>
                    <div className='group-14'>
                      <div className='text-wrapper-8'>
                        Georgia Institute of Technology
                      </div>
                      <div className='text-wrapper-4'>Srijan Kumar</div>
                      <IconLinkedin
                        className='icon-linkedin-instance'
                        color='#5DA4EF'
                      />
                    </div>
                    <img
                      className='img-2'
                      alt='Image'
                      src='https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/6521a2423c4e5cc985cd2c09/img/image-29@2x.png'
                    />
                  </div>
                  <div className='group-15'>
                    <div className='group-16'>
                      <div className='text-wrapper-8'>
                        Managing Director, Moxo
                      </div>
                      <div className='text-wrapper-4'>
                        Balasubramanyam Mohan
                      </div>
                      <IconLinkedin
                        className='icon-linkedin-5-instance'
                        color='#5DA4EF'
                      />
                    </div>
                    <img
                      className='img'
                      alt='Image'
                      src='https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/6521a2423c4e5cc985cd2c09/img/image-28@2x.png'
                    />
                  </div>
                </div>
              </div> */}
            </div>

            {/*  */}
          </div>
        </Box>
      </div>
      {/* <img
        className='frame-2'
        alt='Frame'
        src='https://cdn.animaapp.com/projects/6513c934dd1d181ca884bf3e/releases/65211d62e295b2bd3f0268f3/img/frame-39892-1@2x.png'
      /> */}
    </div>
  );
};
