/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from 'react';

export const MenuCloseMd = ({ className }) => {
  return (
    <svg
      className={`menu-close-md ${className}`}
      fill='none'
      height='24'
      viewBox='0 0 24 24'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        className='path'
        d='M18 18L12 12M12 12L6 6M12 12L18 6M12 12L6 18'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
      />
    </svg>
  );
};
