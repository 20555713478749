/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from 'react';

export const ArrowArrowRightMd = ({ className }) => {
  return (
    <svg
      className={`arrow-arrow-right-md ${className}`}
      fill='none'
      height='24'
      viewBox='0 0 24 24'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        className='path'
        d='M5.14258 12H19.1426M19.1426 12L13.1426 6M19.1426 12L13.1426 18'
        stroke='#0E231C'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
      />
    </svg>
  );
};
