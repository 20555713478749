import { Box, Divider, Grid } from '@mui/material';
import React from 'react';
import './index.css';
import Part1 from './part1';
import Part2 from './part2';
import Part3 from './part3';

export default function Section3() {
  return (
    <Box className='section-grid-wrapper'>
      <Grid container>
        <Grid item xs={12}>
          <div className='v75_86'>
            <div className='v75_87 section-heading-title'>Use Cases</div>
            <div className='v75_88 section-heading-subtitle'>
              Understanding our users and making impact in their lives with
              Tolka
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Part2 />
          <Divider
            className='divider-style'
            style={{ borderColor: '#19639461', marginTop: 30 }}
          />
          <Box className='grid-part-box-wrapper'>
            <Part3 />
          </Box>
          <Divider
            className='divider-style'
            style={{ borderColor: '#19639461', marginTop: 30 }}
          />
          <Box className='grid-part-box-wrapper'>
            <Part1 />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
