/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const EnvironmentRainbow = ({ className }) => {
  return (
    <svg
      className={`environment-rainbow ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M4 22.6667V20C4 13.3726 9.37258 8 16 8C22.6274 8 28 13.3726 28 20V22.6667M8 22.6667V20C8 15.5817 11.5817 12 16 12C20.4183 12 24 15.5817 24 20V22.6667M12 22.6667V20C12 17.7909 13.7909 16 16 16C18.2091 16 20 17.7909 20 20V22.6667"
        stroke="#263D49"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </svg>
  );
};
