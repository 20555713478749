import React from 'react';
import './index.css';
import { Box, Grid } from '@mui/material';
import ImgSrc from './Blog.jpg';
export default function Blog1() {
  const link1 =
    'https://www.symanto.com/blog/the-emerging-role-of-natural-language-processing-in-esg-analytics/';
  const link2 =
    'https://corporate.marksandspencer.com/sustainability/plan-a-our-planet';
  const link3 =
    'https://www.amenityanalytics.com/blog-articles/5-use-cases-for-nlp-in-esg';
  return (
    <>
      <Box className='section-grid-wrapper'>
        <Box className='blogBody'>
          <Box className='section'>
            <Grid container spacing={4}>
              <Grid item md={4} xs={12} style={{ alignSelf: 'center' }}>
                <div className='blog-img-container'>
                  <img src={ImgSrc} style={{ width: '100%' }} />
                </div>
              </Grid>
              <Grid item md={8} xs={12}>
                <Box className=''>
                  <div className='heading_title'>
                    Exploring ESG Use Cases with Tolka
                  </div>
                </Box>
                <p>
                  Environmental, Social, and Governance (ESG) factors are
                  increasingly relevant in investing, operational, and business
                  decisions. However, quality and traceability remain critical
                  challenges for ESG data, along with a lack of global
                  regulation in the space. However, emerging capabilities in AI
                  and NLP technology have the potential to provide new data
                  points and fresh perspectives to existing data to help bridge
                  these gaps.
                  <sup>
                    <a href={link1} target='_blank'>
                      [ 1 ]
                    </a>
                  </sup>
                </p>
                <p>
                  Historically, assessing long-form text has been a time and
                  resource-intensive process. Fortunately, innovations in NLP
                  have revolutionized how unstructured text can be gathered,
                  examined and interpreted. NLP tools, such as Anvai's Tolka
                  platform, can analyze unstructured data quickly and on a
                  massive scale. Users can take unstructured text from public
                  sources and use Tolka to understand how ESG events drive
                  company performance, drilling down to the specific sentences
                  that Tolka tags as material. Tolka delivers these insights via
                  dashboards or APIs integrated into internal systems, with
                  customization options.
                </p>
              </Grid>
            </Grid>
          </Box>
          <Box className='section'>
            <div className='section_heading'>
              Applications of NLP on ESG reporting: Expedite manual analysis
            </div>
            <p>
              NLP can reduce the time it takes to find relevant ESG information
              within reports and other internal and external documents. For
              example, UK high street food and clothing retailer Marks and
              Spencer published this in their sustainability plan:
              <sup>
                <a href={link2} target='_blank'>
                  [ 2 ]
                </a>
              </sup>
            </p>
            <p>
              "Over the past twelve months, we've improved our approach to human
              rights significantly, culminating in the recent Corporate Human
              Rights Benchmark scoring us as the best apparel and food
              business."
            </p>
            <p>
              Instead of manually reading and analyzing the report, the Tolka
              NLP model would perform text classification and sentiment analysis
              on the report to extract only the most relevant excerpts. In this
              instance, the Tolka model would classify the passage above as
              relating to "Human Rights" with a "positive" sentiment value.
              Other sources can also benefit from NLP, such as news reports,
              social media and blogs, to either corroborate or disprove promises
              made in corporate reports.
            </p>
          </Box>
          <Box className='section'>
            <div className='section_heading'>
              Applications of NLP on ESG reporting: React to ESG news in a
              timely fashion
            </div>
            <p>
              Another NLP ESG use case could be around quick reaction times to
              breaking news stories. Tolka can create an ESG tracker on the news
              for key client names, empowering a sales force to react quickly to
              emerging ESG risks faced by their accounts, create targeted
              pitches to accounts with negative sentiment, and validate their
              campaigns by systematically tracking news.
            </p>
            <p>
              Clients may also worry about missing important ESG information in
              the news — information that isn't captured in company disclosures
              or by survey-based data providers.
              <sup>
                <a href={link3} target='_blank'>
                  [ 3 ]
                </a>
              </sup>{' '}
              Clients can leverage Tolka's ESG analytics to track their
              portfolio, learning in real-time when material ESG conversations
              were taking place in the news sources that mattered to them.
              Previously the client would comb through all news that contained a
              set of ESG terms and mentions of their portfolio companies. Now,
              Tolka points them to the riskiest articles daily, helping to
              target their research.
            </p>
          </Box>
        </Box>
      </Box>
    </>
  );
}
