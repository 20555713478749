import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Controller, useForm } from 'react-hook-form';
import {
  BACK_URL,
  COUNTRY_CODE,
  ControllerWrapper,
} from '../../utils/constants';
import axios from 'axios';
import './style.css';
import { CircularProgress, MenuItem, TextField } from '@mui/material';
import { MenuCloseMd } from '../../icons/MenuCloseMd';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ContactNewModal({ open, handleOpen, handleClose }) {
  const DEFAULT_VAL = {
    name: '',
    job_title: '',
    email_address: '',
    company_name: '',
    country_code: '',
    phone_no: '',
    inquiry_details: '',
  };
  const { handleSubmit, reset, control } = useForm({
    shouldUseNativeValidation: true,
    defaultValues: DEFAULT_VAL,
  });
  const [sentState, setSentState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async (data) => {
    console.log(data);
    // return;
    const OBJ = {
      name: 'Name',
      job_title: 'Job Title',
      email_address: 'Email Address',
      company_name: 'Company Name',
      // country_code: 'Country Code',
      // phone_no: 'Phone No',
      inquiry_details: 'Inquiry Details',
    };
    let errObj = null;
    for (let key of Object.keys(data)) {
      if (!OBJ[key]) {
        if (data[key] == undefined || data[key] == '') {
          data[key] = 'NA';
        }
        continue;
      }
      if (data[key] == undefined || data[key] == '') {
        errObj = key;
        break;
      }
    }
    if (errObj !== null) {
      console.log('errObj', errObj);
      // alert(`${OBJ[errObj]} is required`);
      return;
    }
    try {
      setIsLoading(() => true);
      const respo = await axios.post(`${BACK_URL}/api/contact_us`, data);
      console.log('respo', respo);
      reset(DEFAULT_VAL);
      setSentState(() => true);
      // alert('We have received you inquiry.');
    } catch (errorResp) {
      console.log('errorResp', errorResp);
      setIsLoading(() => false);
      const message = errorResp.message;
      alert('Request Failed.');
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className='modal-wrapper'>
        <Box className='modal-head-wrapper'>
          <div className='section-heading-wrapper'>
            <div className='section-heading-title'>Contact Us</div>
            <div className='section-heading-subtitle'>
              Please provide us with your contact information so we can schedule
              a demo for you.
            </div>
          </div>
          <div className='close-button' onClick={handleClose}>
            <MenuCloseMd className='menu-close-MD' />
          </div>
        </Box>
        <Box className='modal-body-wrapper'>
          <Box className='form'>
            <Box className='form-input-wrapper'>
              <Box className='form-input-item'>
                <ControllerWrapper
                  control={control}
                  name='name'
                  label={'Name'}
                />
              </Box>
              <Box className='form-input-item'>
                <ControllerWrapper
                  control={control}
                  name='job_title'
                  label={'Job Title'}
                />
              </Box>
            </Box>

            <Box className='form-input-wrapper'>
              <Box className='form-input-item'>
                <ControllerWrapper
                  control={control}
                  name='email_address'
                  label={'Email Address'}
                  email
                />
              </Box>
              <Box className='form-input-item'>
                <ControllerWrapper
                  control={control}
                  name='company_name'
                  label={'Company Name'}
                />
              </Box>
            </Box>

            <Box className='form-input-wrapper'>
              <Box className='form-input-item'>
                <Controller
                  control={control}
                  name={'country_code'}
                  render={({ field: { onChange, value, ref } }) => (
                    <TextField
                      select
                      label='Country'
                      variant='standard'
                      fullWidth
                      value={value}
                      onChange={onChange}
                      // inputRef={ref}
                      // helperText='Please select your country'
                    >
                      {COUNTRY_CODE.map((option) => (
                        <MenuItem key={option.code} value={option.code}>
                          {option.country} (+{option.code})
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Box>
              <Box className='form-input-item'>
                <ControllerWrapper
                  control={control}
                  name='phone_no'
                  label={'Phone No'}
                  notRequired
                />
              </Box>
            </Box>

            <Box className='form-input-wrapper'>
              <Box className='form-input-item'>
                <ControllerWrapper
                  control={control}
                  name='inquiry_details'
                  label={'Inquiry Detail'}
                  multiline={true}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className='modal-button-wrapper'>
          {sentState ? (
            <Button variant='contained' disabled>
              Inquiry Received
            </Button>
          ) : (
            <>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Button variant='contained' onClick={handleSubmit(onSubmit)}>
                  Submit
                </Button>
              )}
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
}
