import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { VIDEO_MAPPER } from './constant';
import YoutubeEmbed from './youtubeEmbed';

export default function EmbeddedVideos({}) {
  const { id } = useParams();
  console.log('id', id);
  const embedId = VIDEO_MAPPER?.[id || 1] || VIDEO_MAPPER[1];
  console.log('embedId', embedId);
  return (
    <div className='embedded-videos'>
      <YoutubeEmbed embedId={embedId} />
    </div>
  );
}
