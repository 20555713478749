/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const ArrowArrowRightMd2 = ({ className }) => {
  return (
    <svg
      className={`arrow-arrow-right-md-2 ${className}`}
      fill="none"
      height="40"
      viewBox="0 0 40 40"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M8.57153 20H31.9049M31.9049 20L21.9049 10M31.9049 20L21.9049 30"
        stroke="#75CE4C"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
      />
    </svg>
  );
};
